import "./App.css";

import React, { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageViewer from "./components/ImageViewer";

const cardContext = require.context("../public/images/cards/sets", true);

const cardList = cardContext.keys().map((key) => {
  const cardInfo = key.match(/[A-z']+|\d+/gm);
  const set = cardInfo[0];
  const name = (cardInfo[1].charAt(0).toUpperCase() + cardInfo[1].slice(1)).replaceAll('_', ' ');
  const number = cardInfo[2];
  const subset = cardInfo[4]
  const module = cardContext(key);
  const downloadLink = '/images/cards/sets/' + key;
  return { set: set, name: name, number: number, subset: subset, module: module, downloadLink: downloadLink}
});

const setList = [];

cardList.forEach((key) => {
  if (!setList[key.set]) {
    setList[key.set] = [];
  }

  if (!setList[key.set][key.subset]) {
    setList[key.set][key.subset]  = [];
  }

  setList[key.set][key.subset].push({
    name: key.name,
    number: key.number,
    module: key.module,
    downloadLink: key.downloadLink
  });
});

for (const key in setList) {
  for (const key2 in setList[key]) {
    setList[key][key2].sort((a, b) => (a.number > b.number) ? 1 : -1)
  }
}

const setNames = [
  ['Stellar_Miracle', 'i', 'Stellar Miracle'],
  ['Surging_Sparks', 'a', 'Paradise Dragona'],
];

function replaceSubsetNames(array = []) {
  array.forEach((key) => {
    Object.defineProperty(setList[key[0]], key[1] + ' ' + key[2], Object.getOwnPropertyDescriptor(setList[key[0]], key[1]));
    delete setList[key[0]][key[1]]
  })
}

replaceSubsetNames(setNames)

const latestCardContext = require.context("../public/images/cards/latest", true);

const latestCardList = latestCardContext.keys().map((key) => {
  const module = latestCardContext(key);
  const downloadLink = '/images/cards/latest/' + key;
  return { module: module, downloadLink: downloadLink }
});

const LatestCards = ({images, handleMouseEnter, handleMouseLeave}) => {
  return (
    <div className="image-ticker">
      <div className="image-ticker-container my-5">
        {images.map((image, index) => (
          <a download href={image.downloadLink}>
            <img key={index} src={image.module} alt={`${image.module}`} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className="m-3 drop-shadow-lg" />
          </a>
        ))}
        {images.map((image, index) => (
          <a download href={image.downloadLink}>
            <img key={`duplicate-${index}`} src={image.module} alt={`${image.module}`} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} className="m-3 drop-shadow-lg" />
          </a>
        ))}
      </div>
    </div>
  )
}

function App() {
  const [subsetIndex, setSubsetIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);
  const [showImage, setShowImage] = useState(false);

  const SMISubsets = Object.values(Object.values(setList)[0]);
  const SPASubsets = Object.values(Object.values(setList)[1]);


  const subsets = SPASubsets.concat(SMISubsets)

  subsets.map(function(sub, subKey) {
    return Object.values(sub);
  });

  useEffect(() => {
    if(cardIndex < 0) {
      
      setSubsetIndex(subsetIndex-1);
      if(subsetIndex - 1 < 0) {
        setSubsetIndex(1);
        setCardIndex(subsets[1].length -1);
      } else {
        setCardIndex(subsets[subsetIndex - 1].length -1);
      }
    }

    if(cardIndex > subsets[subsetIndex].length-1) {
      setCardIndex(0);
      if(subsetIndex + 1 > subsets.length-1) {
        setSubsetIndex(0);
      } else {
        setSubsetIndex(subsetIndex + 1);
      }
    }
  },
  [subsets, subsetIndex, cardIndex]);

  function viewImage(cardIndex, subsetIndex) {
    setSubsetIndex(subsetIndex);
    setCardIndex(cardIndex);
    setShowImage(true);
  }

  function nextImage() {
    setCardIndex(cardIndex + 1)
  }

  function previousImage() {
    setCardIndex(cardIndex - 1)
  }

  function showViewer(show) {
    setShowImage(show);
  }

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="App flex flex-col">
      <ImageViewer show={showImage} src={subsets[subsetIndex][cardIndex]?.module} nextCallback={nextImage} previousCallback={previousImage} showCallback={showViewer}/>

      <header className="py-5 bg-[#cfcfcf]">
        <h1 className="text-6xl sm:text-6xl drop-shadow-lg">Pokemon Proxies</h1>
        <p className="pt-3">The below proxies have been created with the purpose of testing upcoming cards to be released in future English Pokémon TCG sets.</p>
        <p>These can be downloaded individually and dropped into a text editor, then just print them off and get testing!</p>
      </header>

      <p className="text-3xl underline mt-5">Latest Proxies</p>
      <div className="overlay" style={{ opacity: hoveredIndex !== null ? 0 : 0 }}></div>

      <LatestCards images={latestCardList} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave}/>

      <small className="mb-5">Want to use/playtest with these proxies? Click a proxy to download it!</small>
      <hr className="border-gray-700 mx-8"></hr>
      <div>
        <div>
          <p className="text-3xl underline my-5">All Proxies</p>
          <div className="w-full bg-green-100 text-3xl py-4 text-green-900">
            <strong>NEW</strong>  - You can now click on any of the images below to enlarge them - perfect for recording/streaming! 
          </div>
          <div className="flex flex-col sm:flex-row justify-around">
            <div className={`flex flex-col items-center justify-center sm:w-1/2 py-5 mx-3 mb-3 sm:mb-0 rounded-xl`}>
            <img className="w-2/3 min-w-[250px] max-w-[600px] drop-shadow-lg" src="/images/set-logos/Surging-Sparks-Logo.png" key="surging-sparks" alt="surging-sparks-logo" />
              <p className="pt-5 pb-2 text-sm"><strong>Release Date:</strong> 8th November 2024 - <strong>Total Cards:</strong> 250+</p>
            </div>
          </div>
          <div id="" className="flex justify-center px-8">
            <div className="mt-5">
              {Object.keys(setList['Surging_Sparks']).map((subset, subsetIndex) => (
                <div id={`${subset}-container`} key={subset}>
                  <div className="flex flex-col items-center">
                    <p className="text-3xl underline">{subset.slice(2)}</p>
                    <a href={`../images/${subset.slice(2)} Proxies.zip`} download className="flex flex-row items-center px-2">
                      <div className="text-sm mx-1">Download All</div>
                      <div className="mx-1">
                        <svg width='16px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#1C274C"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#1C274C"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-[1440px] py-5">
                    {setList['Surging_Sparks'][subset].map((card, index) => (
                      <div className="mt-3 mb-6 mx-3" type={card.type}>
                        <LazyLoadImage key={index} src={card.module} alt={`${card.module}`} onClick={() => viewImage(index, subsetIndex)} className="hover:scale-[1.1] transition-transform origin-bottom drop-shadow-lg" />
                        <div className="flex flex-row justify-between px-3 pt-3">
                          <p className="text-xl">
                            #{card.number} - {card.name}
                          </p>
                          <button>
                            <a download href={card.downloadLink}>
                              <svg width='24px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#1C274C"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#1C274C"/>
                              </svg>
                            </a>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-around">
            <div className={`flex flex-col items-center justify-center sm:w-1/2 py-5 mx-3 mb-3 sm:mb-0 rounded-xl`}>
              <img className="w-2/3 min-w-[250px] max-w-[600px] drop-shadow-lg" src="/images/set-logos/Stellar-Crown-Logo.png" key="stellar-crown" alt="stellar-crown-logo" />
              <p className="pt-5 pb-2 text-sm"><strong>Release Date:</strong> 13th September 2024 - <strong>Total Cards:</strong> 143</p>
            </div>
          </div>
          <div id="" className="flex justify-center px-8">
            <div className="mt-5">
              {Object.keys(setList['Stellar_Miracle']).map((subset, subsetIndex) => (
                <div id={`${subset}-container`} key={subset}>
                  <div className="flex flex-col items-center">
                    <p className="text-3xl underline">{subset.slice(2)}</p>
                    <a href={`../images/${subset.slice(2)} Proxies.zip`} download className="flex flex-row items-center px-2">
                      <div className="text-sm mx-1">Download All</div>
                      <div className="mx-1">
                        <svg width='16px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#1C274C"/>
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#1C274C"/>
                        </svg>
                      </div>
                    </a>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 max-w-[1440px] py-5">
                    {setList['Stellar_Miracle'][subset].map((card, index) => (
                      <div className="mt-3 mb-6 mx-3" type={card.type}>
                        <LazyLoadImage key={index} src={card.module} alt={`${card.module}`} onClick={() => viewImage(index, 1)} className="hover:scale-[1.1] transition-transform origin-bottom drop-shadow-lg" />
                        <div className="flex flex-row justify-between px-3 pt-3">
                          <p className="text-xl">
                            #{card.number} - {card.name}
                          </p>
                          <button>
                            <a download href={card.downloadLink}>
                              <svg width='24px' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z" fill="#1C274C"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z" fill="#1C274C"/>
                              </svg>
                            </a>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <footer className="py-5 bg-[#cfcfcf]">
        <p>The trademarks and intellectual property rights associated with Pokemon belong to Nintendo, Creatures, The Pokemon Company International and GAMEFREAK.</p>
        <p>PokemonProxies.com is an independent project and it is not endorsed by, affiliated with, or sponsored by Nintendo, Creatures, The Pokemon Company International or GAMEFREAK.</p>
        <p>This website uses cookies.</p>
      </footer>
    </div>
  );
}

export default App;
